<template>
  <custom-dialog :dialog="dialog" @outsideClick="$emit('update:dialog', false)">
    <DialogTitle :title='$vuetify.lang.t("$vuetify.supplier")'/>

    <v-card-text class="pb-0">
      <v-form v-model="valid" ref="form" lazy-validation>
        <v-container fluid>
          <v-row>
            <v-col class="py-0" cols="12" lg="5" md="5" sm="5">
              <v-select
                :items="categoryList"
                :label="$vuetify.lang.t('$vuetify.Category6')"
                v-model="myObj.type_code"
              />
            </v-col>

            <!-- Company Name -->
            <v-col class="py-0" cols="12" lg="4" md="4" sm="4">
              <v-text-field
                :label="$vuetify.lang.t('$vuetify.companyName')"
                v-model="myObj.company"
                :rules="generalRule"
              />
            </v-col>

            <!-- Province -->
            <v-col class="py-0" cols="12" lg="3" md="3" sm="3">
              <v-text-field
                :label="$vuetify.lang.t('$vuetify.province')"
                v-model="myObj.province"
              />
            </v-col>

            <!-- City -->
            <v-col class="py-0" cols="12" lg="4" md="4" sm="4">
              <v-text-field
                :label="$vuetify.lang.t('$vuetify.city')"
                v-model="myObj.city"
              />
            </v-col>

            <!-- District -->
            <v-col class="py-0" cols="12" lg="4" md="4" sm="4">
              <v-text-field
                :label="$vuetify.lang.t('$vuetify.district')"
                v-model="myObj.district"
              />
            </v-col>

            <!-- Address -->
            <v-col class="py-0" cols="12" lg="4" md="4" sm="4">
              <v-text-field
                :label="$vuetify.lang.t('$vuetify.addr')"
                v-model="myObj.address"
                :rules="generalRule"
              />
            </v-col>

            <!-- Phone -->
            <v-col class="py-0" cols="12" lg="6" md="6" sm="6">
              <v-text-field
                :label="$vuetify.lang.t('$vuetify.mobile')"
                v-model="myObj.tel"
                :rules="generalRule"
              />
            </v-col>

            <!-- Fax -->
            <v-col class="py-0" cols="12" lg="6" md="6" sm="6">
              <v-text-field
                :label="$vuetify.lang.t('$vuetify.fax')"
                v-model="myObj.fax"
              />
            </v-col>

            <!-- contactPerson -->
            <v-col class="py-0" cols="12" lg="4" md="4" sm="4">
              <v-text-field
                :label="$vuetify.lang.t('$vuetify.contactPerson')"
                v-model="myObj.contact"
                :rules="generalRule"
              />
            </v-col>

            <!-- Email -->
            <v-col class="py-0" cols="12" lg="4" md="4" sm="4">
              <v-text-field
                :label="$vuetify.lang.t('$vuetify.email')"
                v-model="myObj.email"
                type="email"
              />
            </v-col>

            <!-- Payment Contact person -->
            <v-col class="py-0" cols="12" lg="4" md="4" sm="4">
              <v-text-field
                :label="$vuetify.lang.t('$vuetify.paymentContactPerson')"
                v-model="myObj.bank_user"
              />
            </v-col>

            <!-- bankName -->
            <v-col class="py-0" cols="12" lg="5" md="5" sm="5">
              <v-text-field
                :label="$vuetify.lang.t('$vuetify.bankName')"
                v-model="myObj.bank_name"
              />
            </v-col>

            <!-- Bank Account No -->
            <v-col class="py-0" cols="12" lg="4" md="4" sm="4">
              <v-text-field
                :label="$vuetify.lang.t('$vuetify.bankAccNo')"
                v-model="myObj.bank_account"
              />
            </v-col>

            <!-- bankSwiftCode -->
            <v-col class="py-0" cols="12" lg="3" md="3" sm="3">
              <v-text-field
                :label="$vuetify.lang.t('$vuetify.bankSwiftCode')"
                v-model="myObj.bank_swift_code"
              />
            </v-col>

            <!-- bankCountry -->
            <v-col class="py-0" cols="12" lg="6" md="6" sm="6">
              <v-text-field
                :label="$vuetify.lang.t('$vuetify.bankCountry')"
                v-model="myObj.bank_country"
              />
            </v-col>

            <!-- supplierRating -->
            <v-col class="py-0" cols="12" lg="6" md="6" sm="6">
              <v-text-field
                :label="$vuetify.lang.t('$vuetify.supplierRating')"
                v-model="myObj.rating"
              />
            </v-col>

            <!-- Remark -->
            <v-col cols="12" class="pb-0">
              <v-textarea
                :label="$vuetify.lang.t('$vuetify.remarks')"
                outlined
                v-model="myObj.note"
              />
            </v-col>
          </v-row>
        </v-container>
      </v-form>
    </v-card-text>

    <v-card-actions class="pb-8">
      <v-spacer />

      <v-btn color="red darken-2" outlined @click="cancelForm">
        {{ $vuetify.lang.t("$vuetify.cancel") }}
      </v-btn>

      <v-btn color="green darken-2" @click="validate">
        {{ $vuetify.lang.t("$vuetify.save") }}
      </v-btn>

      <v-spacer v-if="$vuetify.breakpoint.xsOnly" />
    </v-card-actions>
  </custom-dialog>
</template>

<script>
export default {
  name: "SupplierCRUD",
  props: { dialog: Boolean, myObj: Object, category: Array },
  data() {
    return {
      valid: false,
      categoryList: [],
      generalRule: [(v) => !!v || "this field is required"],
    };
  },
  methods: {
    validate() {
      this.$refs.form.validate() == true
        ? this.$emit("handledata", this.myObj)
        : console.log("false");
    },
    cancelForm() {
      this.$emit("update:dialog", false);
    },
    reset() {
      this.$refs.form.reset();
    },
  },
  watch: {
    category() {
      for (var i = 0; i < this.category.length; i++) {
        this.categoryList[i] = {
          text: this.category[i].text,
          value: this.category[i].type_code,
        };
      }
    },
  },
};
</script>